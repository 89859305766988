<template>
  <div class="reimburseView">
    <detail-top 
      :tabsList="tabsList" 
      :createTime="detailInfo.createTime"
      :headerTitle="`查看${detailInfo.requestType === 1 ? '业务' : '费用'}请款详情`"
      :title="`${detailInfo.createUserName || ''}提交的${detailInfo.requestType === 1 ? '业务' : '费用'}请款审批申请`"
    >
     <div v-if="detailInfo.cashRequirementResends && detailInfo.cashRequirementResends.length !== 0" 
        class="cut" @click="handleCutPage">
        <van-icon name="exchange" />
        <span>查看失败重发</span>
      </div>
    </detail-top>
    <div :class="[auditAuthority == 1 ? 'on-approval' : '', 'content-wrap']">
      <my-card name="1" title="基础信息">
        <div class="basics-wrap">
          <my-cell title="申请单号" :value="detailInfo.billNO" />
          <my-cell title="所属部门" :value="detailInfo.departmentName" />
          <my-cell title="到款所属月份" :value="detailInfo.billMonth" />
          <my-cell title="请款事由" :value="detailInfo.reasons" />
          <my-cell title="所属合同" :value="detailInfo.contractName" />
          <my-cell title="所属项目" :value="detailInfo.projectName" />
          <my-cell title="是否垫付" v-if="detailInfo.requestType == 1" :value="detailInfo.allowAdvances == 1 ? '是' : '否'" />
          <my-cell title="垫付金额" v-if="detailInfo.allowAdvances == 1" :value="detailInfo.advancesAmount" />
          <my-cell title="垫付金是否已开票" v-if="detailInfo.allowAdvances == 1" :value="detailInfo.invoiceStatus == 1 ? '是' : '否'" />
          <my-cell title="是否开票" v-if="detailInfo.requestType == 2" :value="detailInfo.invoiceStatus == 0 ? '未开票' : detailInfo.invoiceStatus == 1 ? '已开票' : '其它'" />
          <my-cell title="付款方式" :value="detailInfo.paymentMethod === 1 ? '转账' : '现金'" />
          <my-cell title="付款单位" :value="detailInfo.companyName" />
          <my-cell title="发票类型" :value="detailInfo.provideType === 1 ? '单笔' : '批量'" />
          <my-cell title="请款总金额" :value="`￥${detailInfo.totalAmount}`" />
          <my-cell title="合同认领金额" v-if="detailInfo.requestType === 1"  :value="`￥${contractBalance}`" />
          <my-cell title="应该实际到款金额" v-if="detailInfo.requestType === 1"  :value="`￥${detailInfo.paymentAmount}`" />
          <my-cell title="收款资料" :value="detailInfo.information" />
          <my-cell title="备注" :value="detailInfo.remark" />
          <img v-if="statusImg" class="status-img" :src="statusImg" />
        </div>
      </my-card>

      <my-card name="2" title="请款明细">
        <template v-slot:right>
          共计{{ detailsTotal }}条
        </template>
        <div class="details-list-wrap">
          <detail-item v-for="(item,index) in detailsList" :key="index" :detail="item" :index="index+1" />
          <div v-if="isUnfold" class="unfold-wrap" @click="handleUnfold">
            <div class="unfold">展开</div>
            <van-icon name="arrow-down" />
          </div>
        </div>
      </my-card>

      <my-card name="3" title="附件">
        <my-files-view :fileList="detailInfo.files"/>
      </my-card>
      <!-- 业用请款：详情module-1，失败重发-8 -->
      <!-- 费用请款：详情module-7，失败重发-9 -->
      <approval-process name="4" v-if="detailInfo.billNO" :billId="detailId" :module="detailInfo.requestType == 1 ? 1 : 7" />
    </div>
    <detail-approval-btn v-if="detailInfo.billNO && auditAuthority == 1" :billNo="detailInfo.billNO" />
  </div>
</template>

<script>
import Vue from 'vue';
import { NavBar, Icon  } from 'vant';
import passImg from '@/assets/approvalPassImg.png'
import rejectImg from '@/assets/approvalRejectImg.png'

Vue.use(NavBar).use(Icon);
import {
  cashdetail,
  reqContractBalance,
} from "../../api/reimburse";
export default {
  name: "reimburseView",
  data() {
    return {
      detailId: '', // id
      tabsList: [
        {
          title: '基础信息',
          key: 1,
        },
        {
          title: '请款明细',
          key: 2,
        },
        {
          title: '附件',
          key: 3,
        },
        {
          title: '审批流程',
          key: 4,
        },
      ],
      detailInfo: {},
      detailsList: [],
      isUnfold: false,
      detailsTotal: 0,
      statusImg: '',
      auditAuthority: 0,
      contractBalance: 0,
    };
  },
  components: {
    ApprovalProcess: () => import('@/components/business/approvalProcess/index.vue'),
    DetailTop: () => import('@/components/business/detailTop/index.vue'),
    MyCard: () => import('@/components/basics/MyCard.vue'),
    MyCell: () => import('@/components/basics/MyCell.vue'),
    MyFilesView: () => import('@/components/basics/MyFilesView.vue'),
    DetailItem: () => import('./components/DetailItem.vue'),
    DetailApprovalBtn: () => import('@/components/business/detailApprovalBtn/index.vue'),
  },
  mounted() {
    // 查看是否有内容
    this.detailId = this.$route.query.id;
    this.auditAuthority = this.$route.query.auditAuthority;
    this.resendAuditAuthority = this.$route.query.resendAuditAuthority;
    if (this.detailId) {
      this.getDetailInfo()
    } else {
      console.log("打开错误");
    }
  },
  methods: {
    getDetailInfo() {
      cashdetail({id: this.detailId}).then(res => {
        let obj = res.resultData;
        this.detailInfo = obj;
        if(obj.requestType == 1){
          this.getContractBalance(this.detailId)
        }
        let list = obj.details;
        this.detailsTotal = list.length
        if(list.length > 3){
          this.detailsList = list.slice(0, 3);
          this.isUnfold = true;
        } else {
          this.detailsList = list;
        }
        if(obj.status === 2){ // 驳回
          this.statusImg = rejectImg;
        } else if(obj.status === 3){ // 通过
          this.statusImg = passImg;
        }
      })
    },
    getContractBalance(contractId){
      reqContractBalance({contractId}).then(res => {
        if(res.success){
          this.contractBalance = `${res.resultData.balanceAmount}(${res.resultData.freezeAmount})`
        }
      })
    },
    handleUnfold(){
      this.detailsList = this.detailInfo.details;
      this.isUnfold = false;
    },
    handleCutPage(){
      this.$router.replace({
        name: 'failureRetryView',
        query: {
          id: this.detailId,
          auditAuthority: this.auditAuthority,
          resendAuditAuthority: this.resendAuditAuthority
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.reimburseView{
  .content-wrap{
    overflow: auto;
    height: calc(100vh - 172px);
    .basics-wrap{
      position: relative;
      .status-img{
        width: 50%;
        position: absolute;
        z-index: 99;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        opacity: 0.3;
      }
    }
    .details-list-wrap{
      .unfold-wrap{
        color: $gray;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .unfold{
          margin-right: 4px;
        }
      }
    }
  }
  >.on-approval{
    height: calc(100vh - 214px);
  }
}

</style>